import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typography, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useNavigate } from 'react-router-dom';

import Feedback from './Feedback';
import BugReport from './BugReport';

function Footer({ profile, jwtToken }) {
  const currentVersion = process.env.REACT_APP_VERSION;
  const navigate = useNavigate();

  return (
    <Grid2 md={12} className="p-1" style={{ backgroundColor: 'primary' }}>
      <Typography align="center" variant="body2">
        <Button
          onClick={() => navigate('/Tnc', {
            profile,
            jwtToken,
          })}
        >
          Terms and Conditions
        </Button>
        |
        {' '}
        <Feedback />
        |
        {' '}
        <BugReport />
        |
        {' '}
        <Button href="https://www.mcgovern.org/">PJMF</Button>
      </Typography>
      <Typography align="center" variant="body2">
        JournaPilot version:
        {' '}
        {currentVersion}
      </Typography>
    </Grid2>
  );
}

Footer.propTypes = {
  profile: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    acceptPrivacyPolicy: PropTypes.string.isRequired,
    acceptTnc: PropTypes.string.isRequired,
  }).isRequired,
  jwtToken: PropTypes.string.isRequired,
};

export default Footer;
