import React, { useEffect } from 'react';
import { Button } from '@mui/material';

function BugReport() {
  useEffect(() => {
    const scriptCollector = document.createElement('script');

    scriptCollector.src = 'https://mcgovern.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-orl6br/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=66f8f8a8';
    scriptCollector.async = true;

    document.body.appendChild(scriptCollector);

    return () => {
      document.body.removeChild(scriptCollector);
    };
  }, []);

  return (
    <Button
      href="#"
      id="bug_button"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      Report a Bug
    </Button>
  );
}

export default BugReport;
