import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';

const pages = [
  'HOME',
  'ABOUT',
  'PROFILE',
  // "HISTORY"
];
const linkMapping = {
  HOME: '/',
  ABOUT: '/about',
  PROFILE: '/profile',
};

function ResponsiveNavBar({ click }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar variant="dense" disableGutters>
          <Icon
            sx={{
              display: { xs: 'none', md: 'flex' },
              mr: 1,
              height: '40px',
              width: '190px',
            }}
          >
            <img
              height="100%"
              alt="JournaPilot logo."
              src={`${process.env.PUBLIC_URL}jp-logo-updated.svg`}
            />
          </Icon>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#051d40"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={linkMapping[page]}
                >
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            color="black"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'black',
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
            <Icon
              center
              sx={{
                display: { xs: 'flex', md: 'none' },
                mr: 1,
                height: '40px',
                width: '190px',
              }}
            >
              <img
                alt="JournaPilot logo."
                src={`${process.env.PUBLIC_URL}jp-logo-updated.svg`}
              />
            </Icon>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: '#051d40', display: 'block' }}
                component={Link}
                to={linkMapping[page]}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Button
            onClick={click}
            sx={{ my: 2, color: '#051d40', display: 'block' }}
          >
            Sign out
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

ResponsiveNavBar.propTypes = {
  click: PropTypes.func.isRequired,
};

export default ResponsiveNavBar;
