import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TextField, LinearProgress, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ShareButton from './ShareButton';

function ResultTab({
  tpost,
  fpost,
  lpost,
  isError,
  twitterActive,
  facebookActive,
  linkedinActive,
  llm,
  slink,
  shareLink,
  jwtToken,
}) {
  const [twitterPost, setTPost] = useState(tpost);
  const [linkedinPost, setLPost] = useState(lpost);
  const [facebookPost, setFPost] = useState(fpost);

  useEffect(() => {
    setTPost(tpost);
    setLPost(lpost);
    setFPost(fpost);
  }, [tpost, lpost, fpost]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'tpost':
        setTPost(value);
        break;
      case 'lpost':
        setLPost(value);
        break;
      case 'fpost':
        setFPost(value);
        break;
      default:
        break;
    }
  };

  return (
    <Grid2
      md={6}
      className="mb-1"
      style={{ overflowY: 'scroll', height: '83vh' }}
    >
      {!isError && twitterActive && (
        <Row className="mb-5 d-flex">
          {/* <center><h3>Twitter</h3></center> */}
          {tpost && (
            <>
              <TextField
                label="X Post"
                name="tpost"
                onChange={handleChange}
                className="mb-2"
                fullWidth
                multiline
                value={twitterPost}
              />
              <ShareButton
                imglink="twitter-share-button-icon.svg"
                grayimglink="twitter-share-button-icon.svg"
                alt="X Share Button"
                post={twitterPost}
                link={slink}
                platform="twitter"
                jwtToken={jwtToken}
              />
            </>
          )}
          {!tpost && (
            <div className="mb-5">
              <center>
                <h4>
                  Loading X Post using
                  {llm}
                </h4>
              </center>
              <LinearProgress color="primary" />
            </div>
          )}
        </Row>
      )}

      {!isError && linkedinActive && (
        <Row className="mb-5">
          {/* <center><h3>LinkedIn</h3></center> */}
          {lpost && (
            <>
              <TextField
                label="LinkedIn Post"
                name="lpost"
                onChange={handleChange}
                className="mb-2"
                fullWidth
                multiline
                defaultValue={lpost}
              >
                {lpost}
              </TextField>
              <ShareButton
                imglink="linkedin-share-button-icon.svg"
                grayimglink="linkedin-share-button-icon-gray.svg"
                alt="Linkedin Share Button"
                post={linkedinPost}
                link={shareLink}
                platform="linkedin"
                jwtToken={jwtToken}
              />
            </>
          )}
          {!lpost && (
            <div className="mb-5">
              <center>
                <h4>
                  Loading LinkedIn Post using
                  {llm}
                </h4>
              </center>
              <LinearProgress color="primary" />
            </div>
          )}
        </Row>
      )}
      {!isError && facebookActive && (
        <Row className="mb-3">
          {/* <center><h3>Facebook</h3></center> */}
          {fpost && (
            <>
              <TextField
                label="Facebook Post"
                name="fpost"
                onChange={handleChange}
                className="mb-2"
                fullWidth
                multiline
                defaultValue={fpost}
              >
                {fpost}
              </TextField>
              <ShareButton
                imglink="facebook-share-button-icon.svg"
                grayimglink="facebook-share-button-icon-gray.svg"
                alt="Facebook Share Button"
                post={facebookPost}
                link={shareLink}
                platform="facebook"
                jwtToken={jwtToken}
              />
            </>
          )}
          {!fpost && (
            <div className="mb-5">
              <center>
                <h4>
                  Loading Facebook Post using
                  {llm}
                </h4>
              </center>
              <LinearProgress color="primary" />
            </div>
          )}
        </Row>
      )}

      {isError && (
        <Row className="mb-3">
          <Typography
            paragraph
            align="justify"
            variant="h6"
            color="red"
            gutterBottom
          >
            Encountered an error while processing the request. Please retry
            after a few minutes or file a bug by clicking the PROVIDE FEEDBACK
            button at the bottom of the page.
          </Typography>
        </Row>
      )}
    </Grid2>
  );
}

ResultTab.propTypes = {
  tpost: PropTypes.string.isRequired,
  fpost: PropTypes.string.isRequired,
  lpost: PropTypes.string.isRequired,
  isError: PropTypes.string.isRequired,
  twitterActive: PropTypes.string.isRequired,
  facebookActive: PropTypes.string.isRequired,
  linkedinActive: PropTypes.string.isRequired,
  llm: PropTypes.string.isRequired,
  slink: PropTypes.string.isRequired,
  shareLink: PropTypes.string.isRequired,
  jwtToken: PropTypes.string.isRequired,
};

export default ResultTab;
