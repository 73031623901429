import React, { useEffect } from 'react';
import { Button } from '@mui/material';

function Feedback() {
  useEffect(() => {
    const scriptCollector = document.createElement('script');

    scriptCollector.src = 'https://mcgovern.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-ia3qmx/b/4/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=9e67410d';
    scriptCollector.async = true;

    document.body.appendChild(scriptCollector);

    return () => {
      document.body.removeChild(scriptCollector);
    };
  }, []);

  return (
    <Button
      href="#"
      id="feedback_button"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      Provide Feedback
    </Button>
  );
}

export default Feedback;
