import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  FormControl,
  Button,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@uidotdev/usehooks';
import Footer from '../components/Footer';

const API_MAPPING = {
  OPENAI_CHATGPT_35: 'OpenAI ChatGPT 4o',
  ANTHROPIC_CLAUDE: 'Anthropic Claude (Beta)',
};

function Profile({ validateProfile, getProfile }) {
  const [profile] = useLocalStorage('profile', {});
  const [jwtToken] = useLocalStorage('jwtToken', null);
  const [email] = useLocalStorage('email', null);

  const [firstName, setFirstName] = useState(profile.firstName ?? null);
  const [lastName, setLastName] = useState(profile.lastName ?? null);
  const [organization, setOrganization] = useState(
    profile.organization ?? null,
  );
  const [state, setStateName] = useState(profile.state ?? null);
  const [role, setRole] = useState(profile.role ?? null);
  const [usStateChoices, setStateChoices] = useState([]);

  const [openaiApiKey, setOpenAiApiKey] = useState(null);
  const [anthropicApiKey, setAnthropicApiKey] = useState(null);
  const [openaiKeyStatus, setOpenAiKeyStatus] = useState(false);
  const [anthropicKeyStatus, setAnthropicKeyStatus] = useState(false);
  const [showApiKeyEditText, editApiKeys] = useState(
    !(profile.openAiApiKeyIsPresent || profile.anthropicApiKeyIsPresent),
  );

  // const [isLoading, setLoading] = useState(true)
  const [formError, raiseError] = useState(false);

  useEffect(() => {
    validateProfile(profile, jwtToken);
  }, []);

  const navigate = useNavigate();

  function getApiHelperText(keyStatus) {
    if (keyStatus === 'valid') {
      return 'Your API Key is VALID';
    }
    if (keyStatus === 'invalid') {
      return 'Your API Key is INVALID';
    }
    if (keyStatus === 'testing') {
      return 'We are testing your API key';
    }
    return null;
  }

  const submitButton = (e) => {
    e.preventDefault();

    if (
      !firstName
      || !lastName
      || openaiKeyStatus === 'invalid'
      || anthropicKeyStatus === 'invalid'
      || (openaiKeyStatus !== 'valid' && openaiApiKey)
      || (anthropicKeyStatus !== 'valid' && anthropicApiKey)
    ) {
      raiseError(true);
      return;
    }
    raiseError(false);

    fetch(`/django/update-profile/?email=${encodeURIComponent(email)}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        email,
        firstName,
        lastName,
        organization,
        state,
        role,
        openAiApiKey: openaiApiKey,
        anthropicApiKey,
      }),
    })
      .then(() => {
        getProfile(email, jwtToken);
      })
      .then(() => {
        navigate('/');
      });
  };

  const deleteApiKeyButton = () => {
    const payload = {
      email,
      openAiApiKey: null,
      anthropicApiKey: null,
    };

    fetch(`/django/update-profile/?email=${encodeURIComponent(email)}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(payload),
    }).then(() => {
      getProfile(email, jwtToken);
    }).then(() => {
      navigate('/profile');
    });
  };

  useEffect(() => {
    async function getStateChoices() {
      await fetch('/django/state-options/', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((result) => result.json())
        .then((data) => {
          //
          setStateChoices(data);
        });
    }
    getStateChoices();
  }, [jwtToken]);

  const testStory = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

  function testApiKey(event, apiType, apiKey) {
    event.preventDefault();
    if (apiType === API_MAPPING.OPENAI_CHATGPT_35) {
      setOpenAiKeyStatus('testing');
    } else if (apiType === API_MAPPING.ANTHROPIC_CLAUDE) {
      setAnthropicKeyStatus('testing');
    }
    fetch(`/django/generate-post/?email=${encodeURIComponent(email)}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        userId: profile.userId,
        story: testStory,
        twitter: true,
        facebook: false,
        linkedin: false,
        llm: apiType,
        api_key: apiKey,
        link: '',
        email,
        test: true,
      }),
    }).then((response) => {
      if (response.status === 400) {
        if (apiType === API_MAPPING.OPENAI_CHATGPT_35) {
          setOpenAiKeyStatus('invalid');
        } else if (apiType === API_MAPPING.ANTHROPIC_CLAUDE) {
          setAnthropicKeyStatus('invalid');
        }
      } else if (response.status === 200) {
        if (apiType === API_MAPPING.OPENAI_CHATGPT_35) {
          setOpenAiKeyStatus('valid');
        } else if (apiType === API_MAPPING.ANTHROPIC_CLAUDE) {
          setAnthropicKeyStatus('valid');
        }
      }
    });
  }

  return (
    // isLoading ?
    // <CircularProgress />
    // :
    <form className="container mt-3 mb-3">
      <Grid2 container spacing={7} display="flex" justifyContent="center">
        <Grid2 md={6}>
          <Row className="mb-3 mt-3">
            <Typography sx={{ fontWeight: 'bold', p: 0 }}>
              User Profile
            </Typography>
          </Row>

          <Row className="mb-3">
            <TextField
              disabled
              label="Email"
              variant="filled"
              shrink
              required
              name="email"
              value={email}
              placeholder="Email"
              color="secondary"
            />
          </Row>
          <Row className="mb-3">
            <TextField
              shrink
              required
              label="First Name"
              name="firstName"
              value={firstName}
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              color="secondary"
            />
          </Row>
          <Row className="mb-3">
            <TextField
              shrink
              required
              label="Last Name"
              name="lastName"
              value={lastName}
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              color="secondary"
            />
          </Row>

          <Row className="mb-3">
            <TextField
              shrink
              label="Organization"
              name="organization"
              value={organization}
              multiline
              placeholder="Organization"
              onChange={(e) => setOrganization(e.target.value)}
              color="secondary"
            />
          </Row>

          <Row className="mb-3">
            <TextField
              shrink
              label="Role"
              name="role"
              value={role}
              multiline
              placeholder="Role"
              onChange={(e) => setRole(e.target.value)}
              color="secondary"
            />
          </Row>

          <Row className="mb-3">
            <TextField
              value={state}
              onChange={(e) => setStateName(e.target.value)}
              select // tell TextField to render select
              label="State"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {usStateChoices.map((choice) => (
                <MenuItem key={choice.value} value={choice.value}>
                  {choice.label}
                </MenuItem>
              ))}
            </TextField>
          </Row>
          <Row className="mb-3">
            <Typography
              sx={{
                fontWeight: 'bold',
                p: 0,
                mb: 1,
                mt: 1,
              }}
            >
              User API Keys (Optional)
            </Typography>

            {showApiKeyEditText ? (
              <>
                <Row className="mb-3">
                  <Typography sx={{ p: 0, mb: 1, mt: 1 }}>
                    If you would like to use your own API keys, please enter
                    them below. If you do not enter your own API keys, we will
                    use a McGovern API Key and limit the number of posts you can
                    generate per day. Once you update your profile with API
                    keys, we will not display them again in the browser for
                    security reasons.
                  </Typography>
                </Row>
                <Row className="p-0 mb-3">
                  <Col md={8} sm={12}>
                    <TextField
                      shrink
                      fullWidth
                      label="OpenAI API Key"
                      name="openAiApiKey"
                      placeholder="OpenAI API Key"
                      onChange={(e) => setOpenAiApiKey(e.target.value)}
                      color="secondary"
                      helperText={getApiHelperText(openaiKeyStatus)}
                    />
                  </Col>
                  <Col
                    md={4}
                    sm={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Button
                      disabled={!openaiApiKey}
                      variant="contained"
                      color="secondary"
                      type="submit"
                      onClick={(e) => {
                        testApiKey(e, 'OpenAI ChatGPT 4o', openaiApiKey);
                      }}
                    >
                      Test API Key
                    </Button>
                  </Col>
                </Row>

                <Row className="p-0 mb-3">
                  <Col md={8} sm={12}>
                    <TextField
                      shrink
                      fullWidth
                      label="Anthropic API Key"
                      name="AnthropicApiKey"
                      placeholder="Anthropic API Key"
                      onChange={(e) => setAnthropicApiKey(e.target.value)}
                      color="secondary"
                      helperText={getApiHelperText(anthropicKeyStatus)}
                    />
                  </Col>
                  <Col
                    md={4}
                    sm={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Button
                      disabled={!anthropicApiKey}
                      variant="contained"
                      color="secondary"
                      type="submit"
                      onClick={(e) => {
                        testApiKey(
                          e,
                          'Anthropic Claude (Beta)',
                          anthropicApiKey,
                        );
                      }}
                    >
                      Test API Key
                    </Button>
                  </Col>
                </Row>
              </>
            )
              : (
                <>
                  <Row className="mb-1">
                    <Typography sx={{
                      fontStyle: 'italic', p: 0, mb: 1, mt: 1,
                    }}
                    >
                      You have previously saved API keys.
                      Would you like to update them or delete them entirely?
                    </Typography>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Col md={4} xs="auto">
                      <Row className="justify-content-center align-items-center">

                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            editApiKeys(true);
                          }}
                        >
                          Edit API Keys
                        </Button>
                      </Row>

                    </Col>
                    <Col md={2} />

                    <Col md={4} xs="auto">
                      <Row className="justify-content-center align-items-center">

                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            deleteApiKeyButton(e);
                          }}
                        >
                          Delete API Keys
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
          </Row>
          <Row>
            <FormControl>
              {formError && (
                <Typography sx={{ mb: 1 }} color="red">
                  Please correct the issues in the form to proceed. You must
                  include a first and last name and any user-provided API keys
                  may not be invalid. Please ensure you have tested any API keys
                  you have entered.
                </Typography>
              )}
              <Form.Group
                controlId="formGridCheckbox"
                className="col col-md-12"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={submitButton}
                  className="me-4 btn btn-md btn-block"
                >
                  Submit
                </Button>
              </Form.Group>
            </FormControl>
          </Row>
        </Grid2>
        <Footer />
      </Grid2>
    </form>
  );
}

Profile.propTypes = {
  getProfile: PropTypes.func.isRequired,
  validateProfile: PropTypes.func.isRequired,
};

export default Profile;
