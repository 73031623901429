import {
  React, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Amplify, Hub } from 'aws-amplify';
import { Typography, Button, LinearProgress } from '@mui/material';
import {
  Authenticator,
  useTheme,
  View,
  Heading,
  Text,
  Link,
  Image,
  ThemeProvider as AmplifyThemeProvider,
} from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import Auth from '@aws-amplify/auth';
import { useLocalStorage } from '@uidotdev/usehooks';
import Home from './pages/Home';
import About from './pages/About';
import Tnc from './pages/Tnc';
import Profile from './pages/Profile';
import PageNotFound from './pages/404page';
// import StoryHistory from './pages/StoryHistory';

import ResponsiveNavBar from './components/ResponsiveNavBar';
import './App.css';
import awsExports from './aws-exports';

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#de8b39',
    },
    secondary: {
      main: '#de8b39',
    },
  },
});

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="JournaPilot logo"
          src={`${process.env.PUBLIC_URL}jp-logo-updated.svg`}
          height="70px"
        />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Typography align="center" variant="body2">
          <Button href="https://www.mcgovern.org/">PJMF</Button>
        </Typography>
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
  },
  SignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
  },
  SetupTOTP: {
    Header() {
      return (
        <>
          <Heading level={3}>Setup MFA</Heading>
          <Text>
            MFA apps:
            <Link href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS">
              {' '}
              Google Authenticator
            </Link>
            ,
            <Link href="https://support.microsoft.com/en-us/account-billing/how-to-use-the-microsoft-authenticator-app-9783c865-0308-42fb-a519-8cf666fe0acc">
              {' '}
              Microsoft Authenticator
            </Link>
            ,
            <Link href="https://support.authy.com/hc/en-us/articles/360006303934-Add-a-New-Two-Factor-Authentication-2FA-Account-Token-in-the-Authy-App">
              {' '}
              Authy
            </Link>
            , and more.
          </Text>
        </>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      return <Heading level={3}>Enter MFA Code</Heading>;
    },
  },
};

function RouteBody({ profile, GetProfile, validateProfile }) {
  return (
    <Routes>
      <Route
        path="/"
        element={(
          <Home
            userId={
            profile !== null && 'userId' in profile
              ? profile.userId
              : null
          }
            validateProfile={validateProfile}
          />
      )}
      />

      <Route path="about" element={<About />} />
      <Route
        path="tnc"
        element={(
          <Tnc
            getProfile={GetProfile}
            validateProfile={validateProfile}
          />
      )}
      />
      <Route
        path="profile"
        element={(
          <Profile
            getProfile={GetProfile}
            validateProfile={validateProfile}
          />
      )}
      />
      <Route path="page404" element={<PageNotFound />} />
    </Routes>
  );
}

function AuthenticatorBody(
  {
    allowIpAddress,
    handleSignOut,
    isLoading,
    profile,
    GetProfile,
    validateProfile,
  },
) {
  if (allowIpAddress) {
    return (
      <Authenticator
        components={components}
      // hideSignUp
        loginMechanisms={['email']}
        variation="default"
      >
        <>
          <ResponsiveNavBar click={handleSignOut} />
          {isLoading ? (
            <LinearProgress color="primary" />
          ) : (
            <RouteBody
              profile={profile}
              GetProfile={GetProfile}
              validateProfile={validateProfile}
            />
          )}
        </>
      </Authenticator>
    );
  }

  return (
    <Typography>
      Your IP address has been identified as originating outside the
      United States. JournaPilot is currently limited to US-based IP
      addresses. If you believe this is a mistake, please contact
      JournaPilot Support at datasolutions@mcgovern.org.
    </Typography>
  );
}

function App() {
  const [, setEmail] = useLocalStorage('email', null);
  const [isLoading, setLoading] = useState(false);
  const [allowIpAddress, setAllowIpAddress] = useState(false);
  const [profile, setProfile] = useLocalStorage('profile', {});
  const [jwtToken, setJwtToken] = useLocalStorage('jwtToken', null);

  const navigate = useNavigate();
  const asyncSetProfile = (key) => Promise.resolve().then(() => {
    setProfile(key);
  });

  const validateProfile = (userProfile, userJwtToken) => {
    if (userProfile && userJwtToken && Object.keys(userProfile).length !== 0) {
      if (!userProfile.acceptTnc || !userProfile.acceptPrivacyPolicy) {
        navigate('/tnc');
      } else if (!userProfile.firstName) {
        navigate('/profile');
      }
    }
  };

  const GetProfile = useCallback(async (userEmail, userJwtToken) => {
    if (userEmail && userJwtToken) {
      setLoading(true);
      await fetch(`/django/profile/?email=${encodeURIComponent(userEmail)}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userJwtToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.length > 0) {
            if (data[0] !== profile) {
              asyncSetProfile(data[0]).then(() => {
                validateProfile(data[0], userJwtToken);
              });
            }
          } else {
            // eslint-disable-next-line no-use-before-define
            CreateProfile(userEmail, userJwtToken);
          }
        })
        .then(() => {
          setLoading(false);
        });
    }
  });

  function CreateProfile(userEmail, userJwtToken) {
    if (userJwtToken) {
      // e.preventDefault();
      fetch(`/django/update-profile/?email=${encodeURIComponent(userEmail)}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userJwtToken}`,
        },
        body: JSON.stringify({
          email: userEmail,
        }),
      }).then(() => {
        setEmail(userEmail);
        GetProfile(userEmail, userJwtToken);
      });
    }
  }

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn': {
        const userJwtToken = data.payload.data.signInUserSession.idToken.jwtToken;
        const userEmail = data.payload.data.signInUserSession.idToken.payload.email;
        setEmail(userEmail);

        if (userJwtToken !== jwtToken) {
          setJwtToken(userJwtToken);
          GetProfile(userEmail, userJwtToken);
        }
        break;
      }

      case 'signOut':
        setProfile(null);
        setEmail(undefined);
        setJwtToken(undefined);
        navigate('/');
        break;
      default:
    }
  });

  // this statement checks whether the JWT token is expired at a regular interval and refreshes
  // if needed
  useEffect(() => {
    const refreshToken = async () => {
      // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed
      // automatically
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const userJwtToken = idToken.getJwtToken();
        setJwtToken(userJwtToken);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('user is not logged in');
      }
    };
    refreshToken();

    // Set up a timer to refresh the token every 90 seconds
    const tokenRefreshInterval = setInterval(
      () => {
        refreshToken();
      },
      50 * 60 * 1000,
    ); // multiply minutes by number of milliseconds in a minute (60 * 1000)

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const checkIp = async () => {
      await fetch('/django/check-ip/', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success === true) {
            setAllowIpAddress(true);
          }
          // to enable local testing, we add the below lines
          if (
            window.location.hostname === 'localhost'
            || window.location.hostname === '127.0.0.1'
          ) {
            setAllowIpAddress(true);
          }
        })
        .then(() => {
          setLoading(false);
        });
    };
    checkIp();
  }, []);

  const amplifyTheme = {
    name: 'Auth Example Theme',
    tokens: {
      colors: {
        brand: {
          primary: {
            10: 'white',
            20: '#de8b39',
            40: '#de8b39',
            60: '#de8b39',
            80: '#de8b39',
            90: '#de8b39',
            100: '#de8b39',
          },
        },
      },
    },
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    Auth.signOut();
  };

  return (
    <ThemeProvider theme={theme}>
      <AmplifyThemeProvider theme={amplifyTheme}>
        {isLoading ? (
          <LinearProgress color="primary" />
        ) : (
          <AuthenticatorBody
            allowIpAddress={allowIpAddress}
            components={components}
            handleSignOut={handleSignOut}
            isLoading={isLoading}
            profile={profile}
            GetProfile={GetProfile}
            validateProfile={validateProfile}
          />
        )}
      </AmplifyThemeProvider>
    </ThemeProvider>
  );
}

AuthenticatorBody.propTypes = {
  allowIpAddress: PropTypes.bool.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    userId: PropTypes.string.isRequired,
  }).isRequired,
  GetProfile: PropTypes.func.isRequired,
  validateProfile: PropTypes.func.isRequired,
};

RouteBody.propTypes = {
  profile: PropTypes.shape({
    userId: PropTypes.string.isRequired,
  }).isRequired,
  GetProfile: PropTypes.func.isRequired,
  validateProfile: PropTypes.func.isRequired,
};

export default App;
