import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row } from 'react-bootstrap';
import {
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@uidotdev/usehooks';

function Tnc({ validateProfile, getProfile }) {
  const [email] = useLocalStorage('email', null);
  const [jwtToken] = useLocalStorage('jwtToken', null);
  const [profile] = useLocalStorage('profile', {});
  const [tncAccept, setTncAccept] = useState(false);
  const [cookieAccept, setCookieAccept] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    validateProfile(profile, jwtToken);
  }, []);

  const handlCheckboxChange = (e) => {
    if (e.target.name === 'tnc_accept') {
      setTncAccept(e.target.checked);
      setButtonDisabled(!(e.target.checked && cookieAccept));
    }
    if (e.target.name === 'cookie_accept') {
      setCookieAccept(e.target.checked);
      setButtonDisabled(!(e.target.checked && tncAccept));
    }
  };

  const submitButton = (e) => {
    e.preventDefault();

    fetch(`django/update-profile/?email=${encodeURIComponent(email)}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        email,
        acceptTnc: new Date(),
        acceptPrivacyPolicy: new Date(),
      }),
    })
      .then(() => {
        e.preventDefault();
        getProfile(email, jwtToken);
      })
      .then(() => {
        navigate('/', { email, user_id: profile.userId });
      });
  };

  return (
    <form className="container mt-3 mb-3">
      <Row className="mx-5 mb-3">
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Please read through our terms of use and accept them at the bottom of
          this page to continue using JournaPilot.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h3" gutterBottom>
          JOURNAPILOT PLATFORM TERMS OF USE
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            THESE JOURNAPILOT PLATFORM TERMS OF USE (“TOU”) FORM A LEGAL
            AGREEMENT BETWEEN YOU, THE END USER (“You”) AND THE PATRICK J.
            MCGOVERN FOUNDATION (“Company”) THAT APPLIES EACH TIME YOU USE OR
            ACCESS THE JOURNAPILOT PLATFORM MADE AVAILABLE TO YOU BY COMPANY,
            AND ITS ASSOCIATED CONTENT, FEATURES, TOOLS, AND MATERIALS
            (collectively, the “Platform”). YOU SHOULD THEREFORE READ CAREFULLY
            THE FOLLOWING TERMS AND CONDITIONS AS THEY GOVERN YOUR USE OF THE
            PLATFORM AND ITS FUNCTIONALITY.
          </b>
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            IN PARTICULAR, THIS TOU CONTAINS AN ARBITRATION PROVISION THAT
            WAIVES YOUR RIGHT TO A COURT HEARING AND/OR A JURY TRIAL AND A CLASS
            ACTION WAIVER THAT WAIVES YOUR RIGHT TO PARTICIPATE IN ANY CLASS
            ACTION LAWSUIT TO ENFORCE THIS CONTRACT.
          </b>
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            IF YOU DO NOT AGREE WITH THIS TOU, YOU ARE NOT GRANTED PERMISSION TO
            ACCESS OR OTHERWISE USE THE PLATFORM AND ARE INSTRUCTED TO EXIT,
            UNINSTALL, AND/OR CEASE ALL USE OF THE PLATFORM IMMEDIATELY.
          </b>
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            Company makes its Platform available to independent journalists and
            nonprofit communications teams for the purpose of providing
            technical tools that facilitate their work and the promotion of
            their articles and content.
          </b>
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          License Grant and Restrictions
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          This TOU provides to You a personal, revocable, limited,
          non-exclusive, non-sublicensable, and non-transferable license to use
          and access the Platform solely for Your lawful business use as
          specifically described herein, conditioned on Your continued
          compliance with this TOU.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          When using the Platform in accordance with the foregoing license, You
          shall not directly or indirectly: (a) encumber, sublicense, transfer,
          distribute, rent, lease, time-share or use the Platform in any service
          bureau arrangement or similar commercial purposes; (b) adapt, combine,
          create derivative works of or otherwise modify the Platform; (c)
          disable, circumvent, or otherwise avoid or undermine any security
          device, mechanism, protocol, or procedure implemented in the Platform;
          (d) use or access the Platform for any unlawful, fraudulent,
          deceptive, malicious, or otherwise harmful or injurious purpose; (e)
          remove, obscure, deface, or alter any proprietary rights notices on
          any element of the Platform or accompanying documentation; (f) use the
          Platform in any manner which could damage, disable, overburden, or
          impair the Platform or interfere with any third party’s authorized use
          of the Platform; (g) engage in any “data mining,” “deep-link,”
          “page-scrape,” or use “bots,” “spiders,” or similar data gathering and
          extraction tools or methods in connection with the Platform; or (i)
          probe, scan, or test the vulnerability of the Platform or any systems
          or network infrastructure connected with the Platform, including
          without limitation by stress testing or penetration testing.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          User Obligations
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You represent that You are at least eighteen (18) years of age or the
          legal age of majority in Your jurisdiction (whichever is greater) and
          will, at all times, provide true, accurate, current, and complete
          information (for which You have all necessary rights, permission(s),
          or authority to provide) when submitting information through the
          Platform. In addition, You are responsible for compliance with all
          applicable laws, rules, and regulations with respect to Your use of
          the Platform (including use of any permitted copies of the Platform
          materials and information).
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          The Platform is not intended for use or access by any individual under
          the age of thirteen (13) years, and Company does not knowingly,
          willingly, or intentionally collect personal information from such
          individuals in any medium for any purpose.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Your Account
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You may be required to register an account to use the Platform or
          certain features of the Platform. Each registration is for a single
          user only, unless otherwise expressly agreed by Company. Registration
          for the Platform may also require access credentials, such as a
          username and a password, or adherence to other access requirements as
          designated by Company in its sole discretion from time to time. You
          hereby agree to treat Your access credentials as confidential and not
          to disclose such information to any third party without the express
          prior written consent of Company, which may be granted or withheld in
          its sole discretion. You shall immediately notify Company if You
          suspect or become aware of any loss, theft, or unauthorized use of
          Your login credentials. Company will not be liable for any loss or
          damage arising from Your failure (whether intentional or
          unintentional) to comply with these obligations.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          By submitting the requested information to the registration form or
          similar process on the Platform, You represent and warrant that the
          information You submit for registration is complete, true, accurate,
          and current in all respects. You must maintain and promptly update
          Your submitted account information to ensure that such information is
          complete, true, accurate, and current. Company reserves the right to
          suspend, terminate, or otherwise discontinue Your account and/or
          pending registration if Company has reasonable grounds to suspect
          that: (i) any information You have submitted is untrue, inaccurate,
          not current, or incomplete; (ii) Your registration, account, or use of
          the Platform is in violation of applicable law or this TOU; or (iii)
          You are using the Platform outside of its intended purpose.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Electronic Communications
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Company may send emails or other electronic messages to You concerning
          Your use of the Platform. You consent to receive such electronic
          communications and You agree that all such electronic communications
          constitute valid legal notices satisfying any requirement that such
          notices be in writing.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Submissions
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Certain features of the Platform may permit You to upload, post,
          display, transmit, or otherwise provide certain information, content,
          text, queries, prompts, and other inputs and materials to the
          Platform, as well as hyperlinks to externally hosted materials
          retrievable by the Platform (each a “Submission” and collectively
          “Submissions”).
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You hereby grant to Company a royalty-free, fully transferable, fully
          sublicensable license to use, display, copy, perform, reproduce,
          modify, record, distribute, and create derivative works of Submissions
          in connection with: (i) Company’s operation of the Platform and its
          features and functionalities; and (ii) Company’s research,
          development, and improvement of the Platform.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Without limiting the permitted purposes and effectiveness of the
          foregoing license grant, You expressly acknowledge and agree that
          Company may use Submissions to test, develop, modify, improve, and/or
          update the AI functionalities and processes of the Platform (as “AI”
          is further defined below).
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          In addition, You agree that You will not upload, post, display, or
          transmit any Submission(s) that:
          <ol type="a">
            <li>
              are illegal, defamatory, deceptive, fraudulent, discriminatory,
              tortious, obscene, pornographic, or otherwise objectionable;
            </li>
            <li>
              infringe, misappropriate, or otherwise violate the personal or
              proprietary rights of others;
            </li>
            <li>
              contain any virus, malware, worm, Trojan horse, disabling device,
              or any other harmful or malicious script, code, or tool;
            </li>
            <li>
              impersonates any person or entity or falsely states or otherwise
              misrepresents Your affiliation with a person or entity;
            </li>
            <li>
              unsolicited communications, promotions, or advertisements, or
              spam;
            </li>
            <li>
              harms, harasses, threatens, or violate the rights of any third
              party, or promotes, provokes, or incites violence; or
            </li>
            <li>
              constitutes false advertising, false endorsement, or is otherwise
              false, misleading, or likely to cause consumer confusion.
            </li>
          </ol>
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Company may screen, review, edit, moderate, or monitor Submissions
          from time to time at its discretion, but has no obligation to do so.
          In any event, Company is not responsible for Your or any other user’s
          Submissions, and shall have no liability or responsibility for the
          quality, content, accuracy, legality, or effectiveness of any
          Submissions.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          By uploading, posting, displaying, transmitting, or otherwise
          providing a Submission to the Platform, You represent and warrant
          that: (i) You possess all legal rights required to upload, post,
          display, and/or transmit each Submission and permit Company to use
          such Submission as set forth herein (including without limitation any
          necessary third-party license rights or required consents under
          applicable law); (ii) each Submission is in full compliance with all
          applicable laws and regulations; and (iii) Your Submissions do not
          infringe, misappropriate, or otherwise violate the personal or
          proprietary rights of any third party.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Links to Third-Party Sites and Services
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Certain services, features, or components made available via the
          Platform are delivered by and to third-party providers. By using any
          product, service, or functionality originating from the Platform, You
          hereby acknowledge and consent that Company may share such information
          and data with the applicable third-party provider as may be required
          to enable and facilitate the requested third-party product, service,
          or functionality via the Platform.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            COMPANY EXPRESSLY DISCLAIMS RESPONSIBILITY AND LIABILITY FOR ANY
            THIRD-PARTY MATERIALS, PROGRAMS, APPLICATIONS, TOOLS, PRODUCTS, AND
            SERVICES SET FORTH, DESCRIBED ON, OR ACCESSED THROUGH THE PLATFORM,
            AND YOU AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE FOR ANY LOSS OR
            DAMAGE INCURRED AS A RESULT OF ANY DEALINGS BETWEEN YOU AND A THIRD
            PARTY, WHETHER OR NOT FACILITATED BY OR MADE IN CONNECTION WITH THE
            PLATFORM.
          </b>
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Third-Party Terms and Conditions
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Additional notices, terms, and conditions may apply to products,
          services, receipt of or access to certain materials, participation in
          a particular program, and/or specific portions or features of the
          Platform, including without limitation the terms of third-party
          applications incorporating or utilizing the Platform and/or Your
          accounts with third-party providers of such applications. You hereby
          agree that: (a) this TOU operates in addition to any terms of use
          imposed or required by any such third-party provider; and (b) the
          terms of this TOU supplement and do not alter or amend any such
          third-party terms of use.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Data Privacy
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You understand, acknowledge, and agree that the operation of certain
          features of the Platform may require or involve the provision, use,
          and dissemination of various items of personally identifiable
          information, including without limitation personal contact
          information. Please refer to Company’s Privacy Policy available at
          https://app.getguru.com/card/TjREnMrc/The-Patrick-J-McGovern-Foundation-Privacy-Policy
          (as may be updated by Company from time to time) for a summary of
          Company’s policies and practices regarding personally identifiable
          information.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Proprietary Rights
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          All materials and information included as part of the Platform,
          including without limitation all content, text, graphics, logos, and
          images made available on the Platform, as well as the compilation
          thereof, and any software or other proprietary materials used on or
          integrated with the Platform, are the property of Company or its
          applicable third-party licensors, and are protected by copyright and
          other laws governing intellectual property and proprietary rights.
          Company reserves all rights in the Platform not expressly granted
          herein.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You agree that You do not acquire any ownership rights in any part of
          the Platform under this TOU or through Your use of the Platform.
          Company does not grant You any rights or licenses, express or implied,
          to any intellectual property hereunder except as specifically
          authorized by this TOU.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Feedback
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          “Feedback” means any feedback, recommendations, enhancement requests,
          suggestions, testimonials, endorsements, or ideas for improvement
          communicated to Company by You regarding the Platform or Company’s
          products and services. From time to time, You may voluntarily provide
          Feedback to Company, whether or not in response to specific requests
          or solicitation by Company. In such event, You grant to Company a
          perpetual, irrevocable, fully transferable, freely sublicensable,
          royalty-free, worldwide license to make, have made, use, import, offer
          for sale, sell, reproduce, distribute, modify, adapt, prepare
          derivative works of, display, perform, and otherwise exploit such
          Feedback for any purpose in Company’s discretion without restriction.
          The foregoing license to Feedback shall survive the termination or
          expiration of this TOU for any reason.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Indemnification
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You agree to indemnify, defend, and hold harmless Company and its past
          and present officers, directors, employees, agents, successors, and
          assigns from and against any losses, costs, liabilities, damages, and
          expenses (including reasonable attorney&#39;s fees) relating to or
          arising out of Your violation of (a) this TOU, (b) any personal or
          proprietary rights of a third party, or (c) any applicable laws,
          rules, or regulations. Company reserves the right, at its own cost, to
          assume the exclusive defense and control of any matter otherwise
          subject to indemnification by You, in which event You will fully
          cooperate with Company in asserting any available defenses.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Disclaimers and Excluded Liability
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            COMPANY DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM WILL OPERATE
            ERROR-FREE OR ON AN UNINTERRUPTED BASIS. THE PLATFORM IS PROVIDED
            “AS IS” AND “AS AVAILABLE,” AND TO THE MAXIMUM EXTENT PERMITTED
            UNDER APPLICABLE LAW COMPANY HEREBY DISCLAIMS ANY AND ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY
            IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR
            FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING,
            YOU ACKNOWLEDGE THAT COMPANY MAKES NO REPRESENTATION, WARRANTY, OR
            GUARANTEE WHATSOEVER REGARDING THE QUALITY, ACCURACY, EFFECTIVENESS,
            LEGALITY, RELIABILITY, OR TIMELINESS OF ANY SERVICES, CONTENT, OR
            INFORMATION MADE AVAILABLE IN CONNECTION THEREWITH OR ANY RESULTS
            OBTAINED THEREFROM. YOU AGREE THAT YOUR USE OF AND ACCESS TO THE
            PLATFORM ARE ENTIRELY AT YOUR OWN RISK, AND THAT YOU ARE SOLELY
            LIABLE AND RESPONSIBLE FOR THE RESULTS OF SUCH USE AND ACCESS, SUCH
            THAT COMPANY SHALL HAVE NO LIABILITY OR RESPONSIBILITY THEREFOR
            UNDER ANY LEGAL THEORY OR CAUSE OF ACTION NOW KNOWN OR HEREAFTER
            ESTABLISHED.
          </b>
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            IN NO EVENT SHALL COMPANY BE LIABLE HEREUNDER, REGARDLESS OF THE
            FORM OF CLAIM OR ACTION (WHETHER IN CONTRACT, NEGLIGENCE, STATUTORY
            LIABILITY OR OTHERWISE), FOR ANY LOSS OR INACCURACY OF DATA, LOSS OR
            INTERRUPTION OF USE, OR COST OF PROCURING SUBSTITUTE TECHNOLOGY,
            GOODS, OR SERVICES, OR ANY INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE,
            SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT
            LIMITATION DAMAGES FOR LOSS OF BUSINESS, REVENUES, PROFITS AND/OR
            GOODWILL, EVEN IF SUCH DAMAGES WERE REASONABLY FORESEEABLE OR IF
            COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE
            LIMITATIONS SHALL APPLY NOTWITHSTANDING THE INSUFFICIENCY OR FAILURE
            OF ANY REMEDY PROVIDED HEREIN.
          </b>
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            CERTAIN JURISDICTIONS AND LAWS MAY NOT PERMIT SOME OR ALL OF THE
            DISCLAIMERS OF LIABILITY SET FORTH IN THIS TOU. IN THE EVENT THAT
            SUCH A JURISDICTION OR LAW APPLIES TO THE SUBJECT MATTER OF THIS
            TOU, THE DISCLAIMERS HEREIN CONTAINED WILL APPLY TO THE MAXIMUM
            EXTENT PERMITTED UNDER APPLICABLE LAW.
          </b>
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          No Professional Advice
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          The content and information contained in or made available through the
          Platform, as well as the transactions and other features performed on
          the Platform, cannot replace or substitute for the services of
          qualified professionals in any field, and nothing contained on the
          Platform should be treated or construed as professional advice on
          financial, tax, medical, or legal matters.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            You acknowledge that You are solely responsible and accountable for
            your decisions, actions, and results regarding financial, tax,
            medical, and legal matters, and agree that Company shall not be held
            liable for any decisions made by You in reliance or with reference
            to any content or information that You access on the Platform with
            respect to such matters.
          </b>
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Artificial Intelligence and Machine Learning
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Certain features of the Platform utilize and incorporate artificial
          intelligence, machine learning algorithms, and related technologies
          (collectively, “AI”). You acknowledge that AI is a new and developing
          technology, and that the outputs generated by AI features of the
          Platform may be incomplete, inaccurate, offensive, or otherwise
          unpredictable. In addition to and without limiting any other
          disclaimer of warranty or liability set forth in this TOU, Company
          expressly and specifically disclaims any liability, responsibility,
          and/or guarantee whatsoever regarding the Outputs (defined below) or
          processes of AI features on the Platform.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You agree not to: (i) use the Platform (including without limitation
          any of its AI features) in connection with the creation, testing,
          development, modification, improvement, or updating of any AI owned by
          You and/or a third party; and/or (ii) make any misrepresentation or
          other false or misleading statement, act, or omission regarding the
          presence, utilization, or functionality of AI in the Platform.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          The Platform may generate certain content, information, and other
          outputs made accessible to You, including without limitation in
          response to Your Submissions (collectively, “Outputs”). You agree to
          review Outputs for content, accuracy, and compliance with the terms
          hereof and applicable laws prior to posting, distributing, or
          otherwise disclosing Outputs to any third party. Any such posting,
          distribution, or disclosure of Outputs is conducted entirely at your
          own risk.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Mobile Usage
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          The Platform offers various tools or display functionality that may be
          accessible via Your mobile phone or other mobile computing device.
          Please note that Your mobile carrier’s normal messaging, data, and
          other rates and fees will apply to Your use of the Platform. In
          addition, downloading, installing, or using the Platform on Your
          mobile device may be prohibited or restricted by Your mobile carrier,
          and not all functionality on the Platform will work with all carriers
          or devices or in all locations. Therefore, You are solely responsible
          for: (i) checking with Your mobile carrier to determine if the
          Platform is accessible to Your mobile devices; (ii) the restrictions,
          if any, may be applicable to Your use of the Platform on a mobile
          device; and (iii) determining and keeping track of the network and
          data fees or similar charges associated with such use.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Prohibited Activities
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You may not under any circumstances use or access the Platform in
          connection with any of the following activities:
          <ol type="i">
            <li>
              carrying on propaganda or otherwise attempting to influence
              legislation within the meaning of Section 501(c)(3) of the
              Internal Revenue Code of the United States (the “Code”) and the
              regulations promulgated thereunder;
            </li>
            <li>
              activities that constitute lobbying as defined under the federal
              Lobbying Disclosure Act or similar laws of the United States or of
              any state or locality, and that would require any such payments to
              be reported thereunder; or
            </li>
            <li>
              participating or intervening in (including the publishing or
              distributing of statements) any political campaign on behalf of
              (or in opposition to) any candidate for public office within the
              meaning of Section 501(c)(3) of the Code and the regulations
              thereunder.
            </li>
          </ol>
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Term, Termination, and Suspension
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          This TOU takes effect (or re-takes effect) at the earlier of: (i) your
          electronic acceptance of this TOU on the Platform; and (ii) moment You
          first access, use, and/or log into the Platform. Company reserves the
          right at any time and on any grounds, including without limitation any
          reasonable belief of fraudulent or unlawful activity, to deny or
          suspend Your access to the Platform, or to any portion thereof, in
          order to protect its name and goodwill, its business, its ownership
          and other rights in the Platform or any content or information
          therein, the security and stability of the Platform, and/or the rights
          of others. This TOU terminates automatically if You fail to comply
          with any provision hereof, subject to the survival rights of certain
          provisions identified below. You may also terminate this TOU at any
          time by ceasing to use the Platform, but each re-access or renewed use
          of the Platform will reapply the TOU to You.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Upon termination or expiration of the TOU for any reason, all licenses
          granted by Company hereunder shall immediately terminate, and You must
          immediately cease all use of the Platform. The provisions of this TOU
          concerning Company’s proprietary rights, Feedback, disclaimers of
          warranty, limitations of liability, waiver and severability, entire
          agreement, indemnification rights, arbitration, class action waiver,
          injunctive relief, and governing law will survive the termination of
          this TOU for any reason.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Without limiting the foregoing, Company reserves the right, in its
          sole discretion, to terminate or suspend Your access to the Platform
          or any feature or portion thereof at any time, without notice to You,
          if Company reasonably determines or suspects that You are in violation
          of this TOU.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Arbitration
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          In the event the parties hereto are not able to resolve any dispute
          between them arising out of or concerning this TOU, or any provisions
          hereof, whether arising in contract, tort, or otherwise at law or in
          equity for damages or any other relief, then such dispute shall be
          resolved exclusively through final and binding arbitration pursuant to
          the Federal Arbitration Act, conducted by a single neutral arbitrator
          and administered under the Commercial Arbitration Rules of the
          American Arbitration Association. The exclusive site of such
          arbitration shall be in Boston, Massachusetts. The arbitrator&#39;s
          award shall be final, and judgment may be entered upon it in any court
          having jurisdiction. The prevailing party shall be entitled to recover
          its costs and reasonable attorney&#39;s fees. The entire dispute,
          including the scope and enforceability of this arbitration provision,
          shall be determined by the arbitrator. This arbitration provision
          shall survive the termination of this TOU for any reason.
          Notwithstanding the foregoing, Company may bring a claim for
          injunctive relief against Your violation of this TOU in any court of
          competent jurisdiction.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Class Action Waiver
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            BY USING THE PLATFORM AND AGREEING TO THESE TERMS, YOU HEREBY
            WILLINGLY, EXPRESSLY, AND KNOWINGLY WAIVE ALL RIGHT TO BRING OR
            PARTICIPATE IN ANY CLASS-ACTION LAWSUIT, CLASS-WIDE ARBITRATION, OR
            PRIVATE ATTORNEY-GENERAL ACTION BROUGHT UNDER OR IN CONNECTION WITH
            THIS TOU OR YOUR USE OF THE PLATFORM. YOU MAY NOT BRING ANY CLAIM,
            SUIT, OR OTHER PROCEEDING TO ENFORCE THIS TOU AS THE MEMBER OF ANY
            CLASS OR AS PART OF ANY SIMILAR COLLECTIVE OR CONSOLIDATED ACTION.
          </b>
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Governing Law
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          This TOU is governed by the laws of the Commonwealth of Massachusetts,
          without reference to its principles of conflict of laws. Subject to
          the foregoing arbitration requirements, any claim, suit, or other
          proceeding brought between the You and Company under this TOU shall be
          adjudicated exclusively in the state and federal courts sitting in the
          Commonwealth of Massachusetts, and You hereby expressly submit to such
          jurisdiction for the final resolution thereof.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          The Platform may not be used or accessed from or in any jurisdiction
          that does not give effect to all provisions of this TOU, including
          without limitation this paragraph.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Independent Contractors
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          The relationship between You and Company under this TOU is that of
          independent contractors, and nothing herein or in Your use of the
          Platform shall be construed as creating any joint venture,
          partnership, employment, or agency relationship.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Security and Compliance
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Company reserves the right to view, monitor, and record Your activity
          on the Platform without notice or permission from You. Company’s
          provision of the Platform is subject to existing laws and legal
          process, and nothing contained herein shall restrict or reduce
          Company’s ability to comply with governmental, court, and law
          enforcement requests or requirements involving Your use of the
          Platform or information provided to or gathered by Company with
          respect to such use.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Export Controls
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You represent and warrant that You are not: (a) located in a country
          that is subject to a U.S. government embargo or sanction; or (b)
          listed on any U.S. government list of prohibited or restricted
          parties. You hereby agree that (i) You will comply with all applicable
          sanctions and export control laws, and (ii) You are solely responsible
          for ensuring that the Platform is used, disclosed, and/or accessed
          only in accordance with all applicable sanctions and export control
          laws.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Severability and Waiver
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          If any part of this TOU is determined to be invalid or unenforceable
          pursuant to court order or other operation of applicable law, such
          provision shall be deemed reformed or severed from this TOU to the
          minimum extent required by such order or law, and the remainder of
          this TOU shall continue in full force and effect to the maximum extent
          permitted under applicable law.
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          Company’s failure to insist on strict performance of any of this TOU
          will not operate as a waiver of any subsequent default or failure of
          performance by You. No waiver by Company of any right under this TOU
          will be deemed to be either a waiver of any other right or provision
          or a waiver of that same right or provision at any other time.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Injunctive Relief
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          You acknowledge that any breach, threatened or actual, of this TOU
          would cause irreparable injury to Company not readily quantifiable as
          money damages, such that Company would not have an adequate remedy at
          law. You therefore agree that Company shall be entitled, in addition
          to other available remedies, to seek and be awarded an injunction or
          other appropriate equitable relief from a court of competent
          jurisdiction restraining any such breach of Your obligations, without
          the necessity of posting bond or other security.
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Changes to Terms
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          <b>
            Please note that Company reserves the right to change the terms and
            conditions of this TOU by posting a revised set of terms to this
            location, and/or providing such alternate notification to You as may
            be expressly required by applicable law. In addition, Company may
            add, modify, or delete any aspect, component, or feature of the
            Platform, but Company is not under any obligation to add any
            upgrade, enhancement, or modification. Your continued use of the
            Platform following any announced change will be deemed as conclusive
            acceptance of any change to the TOU. Accordingly, please review the
            TOU on a periodic basis.
          </b>
        </Typography>
        <Typography className="mb-3" color="primary" variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography paragraph align="justify" variant="body1" gutterBottom>
          If You have any questions or comments regarding this TOU, please
          contact Company at: compliance@mcgovern.org
        </Typography>
      </Row>
      {profile.acceptPrivacyPolicy && profile.acceptTnc ? (
        <Row className="mx-5 px-2">
          <Typography
            paragraph
            align="justify"
            variant="body1"
            sx={{ fontStyle: 'italic' }}
            gutterBottom
          >
            You have already accepted the TOU and Privacy Policy.
          </Typography>
        </Row>
      ) : (
        <>
          <Row className="mx-5 px-2">
            <FormControl>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="tnc_accept"
                    onChange={handlCheckboxChange}
                    color="secondary"
                  />
                )}
                label="By clicking ‘I ACCEPT’ below, I acknowledge and agree to the provisions of the TOU which govern dispute resolution, which waive my right to participate in a class action to enforce the TOU, and require arbitration for any claim under the TOU, waiving my right to a jury trial."
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    name="cookie_accept"
                    onChange={handlCheckboxChange}
                    color="secondary"
                  />
                )}
                label={(
                  <Typography>
                    By clicking &#39;I ACCEPT&#39; below, you acknowledge the
                    terms of our
                    {' '}
                    <nbsp />
                    <a href="https://app.getguru.com/card/TjREnMrc/The-Patrick-J-McGovern-Foundation-Privacy-Policy-">
                      Privacy Policy
                    </a>
                    .
                  </Typography>
                )}
              />
            </FormControl>
          </Row>
          <Row className="mt-3 mx-5">
            <Form.Group controlId="formGridCheckbox" className="col col-md-12">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={submitButton}
                className="me-4 btn btn-block"
                disabled={buttonDisabled}
              >
                I ACCEPT
              </Button>
            </Form.Group>
          </Row>
        </>
      )}
    </form>
  );
}

Tnc.propTypes = {
  getProfile: PropTypes.func.isRequired,
  validateProfile: PropTypes.func.isRequired,
};

export default Tnc;
