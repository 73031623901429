import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row } from 'react-bootstrap';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import validator from 'validator';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  FormControl,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Typography,
} from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';
import Footer from '../components/Footer';
import ResultTab from '../components/ResultTab';

const API_MAPPING = {
  OPENAI_CHATGPT_35: 'OpenAI ChatGPT 4o',
  ANTHROPIC_CLAUDE: 'Anthropic Claude (Beta)',
};

function Home({ userId, validateProfile }) {
  const [profile] = useLocalStorage('profile', {});
  const [jwtToken] = useLocalStorage('jwtToken', null);
  const [email] = useLocalStorage('email', null);

  // const [does_profile_exist] = useState(props.does_profile_exist);
  const [atLimit, toggleLimit] = useState(false);
  const [story, setStory] = useState('');
  const [link, setLink] = useState('');
  const [twitter, setTwitter] = useState(false);
  const [facebook, setFacebook] = useState(false);
  const [linkedin, setLinkedin] = useState(false);
  const [tpost, setTPost] = useState('');
  const [lpost, setLPost] = useState('');
  const [fpost, setFPost] = useState('');
  const [slink, setSlink] = useState('');
  const [shareLink, setShareLink] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isStoryError, setIsStoryError] = useState(false);
  const [storyErrorMessage, setStoryErrorMessage] = useState(null);
  const [isLinkError, setIsLinkError] = useState(false);
  const [linkErrorMessage, setLinkErrorMessage] = useState(null);
  const [twitterActive, setTwitterActive] = useState(false);
  const [facebookActive, setFacebookActive] = useState(false);
  const [linkedinActive, setLinkedinActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const [llm, setLlm] = useState('OpenAI ChatGPT 4o');
  const [applyLimit, changeApplyLimit] = useState(false);

  const handleApplyLimit = (userProfile) => {
    // if user has supplied api key and that is the LLM they are using, then do not apply limit
    if (
      userProfile.openAiApiKeyIsPresent
      && llm === API_MAPPING.OPENAI_CHATGPT_35
    ) {
      changeApplyLimit(false);
      return false;
    }
    if (
      userProfile.anthropicApiKeyIsPresent
      && llm === API_MAPPING.ANTHROPIC_CLAUDE
    ) {
      changeApplyLimit(false);
      return false;
    }

    changeApplyLimit(true);
    return true;
  };

  async function getStoryCount() {
    await fetch(`/django/story-count/?email=${encodeURIComponent(email)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result === false && applyLimit) {
          toggleLimit(true);
        }
      });
  }

  useEffect(() => {
    if (userId) {
      if (applyLimit === false) {
        toggleLimit(false);
      } else {
        getStoryCount();
      }
    }
  }, [userId, applyLimit]);

  useEffect(() => {
    handleApplyLimit(profile);
  }, [llm, profile]);
  useEffect(() => {
    validateProfile(profile, jwtToken);
  }, []);

  const resultProps = {
    tpost,
    fpost,
    lpost,
    isError,
    twitterActive,
    facebookActive,
    linkedinActive,
    llm,
    slink,
    shareLink,
    jwtToken,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'story') {
      if (value.length < 200) {
        setIsStoryError(true);
        setStoryErrorMessage(
          'Stories shorter than 200 characters are not supported',
        );
      } else if (value.length > 50000) {
        setIsStoryError(true);
        setStoryErrorMessage(
          'Stories longer than 50000 characters are not supported. You can cut off the story to generate social posts.',
        );
      } else {
        setIsStoryError(false);
        setStoryErrorMessage(null);
      }
    }

    if (name === 'link') {
      if (value.length === 0 || validator.isURL(value)) {
        setIsLinkError(false);
        setLinkErrorMessage(null);
      } else {
        setIsLinkError(true);
        setLinkErrorMessage(
          'Please provide a valid link. You can choose to leave this field empty if you just want to generate posts.',
        );
      }
    }

    switch (name) {
      case 'story':
        setStory(value);
        break;
      case 'link':
        setLink(value);
        break;
      case 'twitter':
        setTwitter(value);
        break;
      case 'facebook':
        setFacebook(value);
        break;
      case 'linkedin':
        setLinkedin(value);
        break;
      case 'llm':
        setLlm(value);
        break;
      default:
        break;
    }
  };

  const handlCheckboxChange = (e) => {
    const { name, checked } = e.target;
    switch (name) {
      case 'twitter':
        setTwitter(checked);
        break;
      case 'facebook':
        setFacebook(checked);
        break;
      case 'linkedin':
        setLinkedin(checked);
        break;
      default:
        break;
    }
  };

  const submitButton = (e) => {
    e.preventDefault();
    setLPost('');
    setTPost('');
    setFPost('');
    setShareLink('');
    setSlink('');
    // setTwitterActive(true);
    // setFacebookActive(true);
    // setLinkedinActive(true);
    setIsVisible(true);
    setIsError(false);

    fetch(`/django/generate-post/?email=${encodeURIComponent(email)}`, {
      // we will check whether the user is at their limit on the backend because we also have to
      // take into account whether they are using their own API key (which we do not want to
      // transmit between the client and backend on every request)

      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        userId,
        story,
        link,
        twitter,
        facebook,
        linkedin,
        llm,
        email,
        test: false,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        toggleLimit(data.over_limit);

        setLPost(data.lpost);
        setTPost(data.tpost);
        setFPost(data.fpost);
        setShareLink(data.link);
        setSlink(data.slink);
        setIsError(data.error);
      });
    setTwitterActive(twitter);
    setFacebookActive(facebook);
    setLinkedinActive(linkedin);
  };

  return (
    <form className="container mt-3 mb-3">
      <Grid2 container spacing={7} display="flex" justifyContent="center">
        <Grid2 md={6}>
          <Row className="mb-3">
            <TextField
              name="story"
              multiline
              rows={18}
              label="Story"
              onChange={handleChange}
              error={isStoryError}
              helperText={storyErrorMessage}
              color="secondary"
            />
          </Row>
          <Row className="mb-3">
            <TextField
              name="link"
              label="Link"
              onChange={handleChange}
              error={isLinkError}
              helperText={linkErrorMessage}
              color="secondary"
            />
          </Row>
          <Row className="mb-3">
            <FormControl fullWidth>
              <InputLabel>LLM</InputLabel>
              <Select
                defaultValue="OpenAI ChatGPT 4o"
                label="LLM"
                name="llm"
                onChange={handleChange}
              >
                <MenuItem value="OpenAI ChatGPT 4o">
                  OpenAI ChatGPT 4o
                </MenuItem>
                <MenuItem value="Anthropic Claude (Beta)">
                  Anthropic Claude (Beta)
                </MenuItem>
              </Select>
            </FormControl>
            {applyLimit ? (
              <Typography sx={{ fontStyle: 'italic', p: 0, mt: 2 }}>
                You have not provided your own API key for this LLM, so we will
                use a PJMF API key and limit you to ten posts per day.
              </Typography>
            ) : (
              <Typography sx={{ fontStyle: 'italic', p: 0, mt: 2 }}>
                You have provided your own API key for this LLM, so we will not
                limit the number of posts you can generate per day.
              </Typography>
            )}
          </Row>
          <Row>
            <FormControl>
              <div key="inline-checkbox" className="mb-3">
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="twitter"
                      onChange={handlCheckboxChange}
                      color="secondary"
                    />
                  )}
                  label="X (Twitter)"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="linkedin"
                      onChange={handlCheckboxChange}
                      color="secondary"
                    />
                  )}
                  label="LinkedIn"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="facebook"
                      onChange={handlCheckboxChange}
                      color="secondary"
                    />
                  )}
                  label="Facebook"
                />
              </div>
            </FormControl>
            {atLimit && (
              <Typography sx={{ p: 0, mb: 1 }} color="red">
                You have reached your daily limit.
              </Typography>
            )}
          </Row>
          <Row>
            <FormControl>
              <Form.Group
                controlId="formGridCheckbox"
                className="col col-md-12"
              >
                <Button
                  disabled={
                    atLimit
                    || isStoryError
                    || story.length === 0
                    || isLinkError
                    || !(twitter || facebook || linkedin)
                  }
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={submitButton}
                  className="me-4 btn btn-md btn-block"
                >
                  Submit
                </Button>
              </Form.Group>
            </FormControl>
          </Row>
        </Grid2>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {isVisible && <ResultTab {...resultProps} />}
        <Footer jwtToken={jwtToken} profile={profile} />
      </Grid2>
    </form>
  );
}

Home.propTypes = {
  userId: PropTypes.string.isRequired,
  validateProfile: PropTypes.func.isRequired,
};

export default Home;
