import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function ShareButton({
  link,
  platform,
  jwtToken,
  grayimglink,
  post,
  alt,
  imglink,
}) {
  const [open, setOpen] = useState(false);
  const [copyFeedback, setCopyFeedback] = useState('');
  const [cOpen, setCOpen] = useState(false);
  const [cCopyFeedback, setCCopyFeedback] = useState('');
  const [isLinkProvided, setIsLinkProvided] = useState(false);

  useEffect(() => {
    if (link.length > 0 || platform === 'twitter') {
      setIsLinkProvided(true);
    }
  }, [link, platform]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyClose = () => {
    setCOpen(false);
  };

  const onShareClick = async (e) => {
    fetch('/django/logger/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        logMessage: `Share button clicked for ${platform}`,
      }),
    });

    e.preventDefault();
    if (platform === 'twitter') {
      window.open(
        `https://x.com/intent/tweet?text=${encodeURIComponent(post)}`,
        'popup',
        'width=600,height=600',
      );
    } else {
      try {
        await navigator.clipboard.writeText(post);
        setCopyFeedback('Copied Successfully');
        setOpen(true);

        if (platform === 'linkedin') {
          window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
              link,
            )}`,
            'popup',
            'width=600,height=600',
          );
        } else if (platform === 'facebook') {
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              link,
            )}`,
            'popup',
            'width=600,height=600',
          );
        }
      } catch (err) {
        setCopyFeedback('Failed to copy. Please check browser permissions');
        setOpen(true);
      }
    }
  };

  const copyToClipboard = async () => {
    fetch('/django/logger/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        logMessage: `Copy button clicked for ${platform}`,
      }),
    });

    try {
      await navigator.clipboard.writeText(post);
      setCCopyFeedback('Copied Successfully');
      setCOpen(true);
    } catch (err) {
      setCCopyFeedback('Failed to copy. Please check browser permissions');
      setCOpen(true);
    }
  };

  return (
    <center>
      {isLinkProvided ? (
        <Tooltip
          open={open}
          onClose={handleClose}
          title={copyFeedback}
          leaveDelay={1500}
        >
          <button
            onClick={(e) => onShareClick(e, post)}
            type="button"
            style={{ background: 'transparent', border: 0 }}
          >
            <img
              src={process.env.PUBLIC_URL + imglink}
              alt={alt}
              style={{ width: '95px' }}
            />
          </button>
        </Tooltip>
      ) : (
        <Tooltip title="Share button disabled since no link was provided">
          <span>
            <button
              disabled
              type="button"
              style={{ background: 'transparent', border: 0 }}
            >
              <img
                src={process.env.PUBLIC_URL + grayimglink}
                alt={alt}
                style={{ width: '95px' }}
              />
            </button>
          </span>
        </Tooltip>
      )}

      <Tooltip
        open={cOpen}
        onClose={handleCopyClose}
        title={cCopyFeedback}
        leaveDelay={2000}
      >
        <Button
          size="small"
          variant="contained"
          disableElevation
          startIcon={<ContentCopyIcon />}
          onClick={() => copyToClipboard()}
          color="primary"
        >
          Copy
        </Button>
      </Tooltip>
    </center>
  );
}

ShareButton.propTypes = {
  post: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  jwtToken: PropTypes.string.isRequired,
  grayimglink: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  imglink: PropTypes.string.isRequired,
};

export default ShareButton;
