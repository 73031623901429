import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

function PageNotFound() {
  return (
    <Box className="mx-5 px-5 my-3 py-3">
      <Typography className="mb-3" color="primary" variant="h3" gutterBottom>
        404 - No Page Found
      </Typography>
    </Box>
  );
}

export default PageNotFound;
