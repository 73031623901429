import React from 'react';
import { Box } from '@mui/system';
import { Typography, ListItem } from '@mui/material';
import Footer from '../components/Footer';

function About() {
  return (
    <Box className="mx-5 px-5 my-3 py-3">
      <Typography className="mb-3" color="primary" variant="h3" gutterBottom>
        About JournaPilot
      </Typography>
      <Typography paragraph align="justify" variant="body1" gutterBottom>
        Empowering journalists to work efficiently is paramount for creating an
        equitable, just, and informed society. Particularly for independent
        journalists and smaller news organizations with limited resources,
        artificial intelligence (AI) and data solutions have the potential to
        revolutionize workflow, boosting their ability to make high-quality
        journalism accessible for mass audiences.
      </Typography>
      <Typography paragraph align="justify" variant="body1" gutterBottom>
        The PJMF Data Solutions team is proud to offer JournaPilot, an
        AI-powered, open-source platform designed to assist journalists and
        communicators by quickly generating story-driven, ready-to-share copy
        for social media, allowing you to focus more on reporting and less on
        social media content creation. With JournaPilot, you can seamlessly
        transform any news article into captivating social media content in just
        a few clicks. In its current version, JournaPilot supports X (formerly
        known as Twitter), Instagram, and Facebook.
      </Typography>
      <Typography
        className="mt-3 mb-2"
        color="primary"
        variant="h5"
        gutterBottom
      >
        About the Design
      </Typography>
      <Typography align="justify" variant="body1" gutterBottom>
        <ListItem className="py-0" sx={{ display: 'list-item', margin: 0 }}>
          Easy-to-use interface streamlines content creation in just a few
          clicks
        </ListItem>
        <ListItem className="py-0" sx={{ display: 'list-item', margin: 0 }}>
          Dual large language model integration (ChatGPT 4o or Claude) allows
          users to bring their own API key and generate multiple variations in
          ready-to-share format
        </ListItem>
        <ListItem className="py-0" sx={{ display: 'list-item', margin: 0 }}>
          Network-level protection, encryption (in transit and at rest), and
          strong authentication ensure operational excellence for safety and
          security
        </ListItem>
      </Typography>
      <Typography
        className="mt-3 mb-2"
        color="primary"
        variant="h5"
        gutterBottom
      >
        About Data Collection
      </Typography>
      <Typography paragraph align="justify" variant="body1" gutterBottom>
        We collect anonymized analytics data such as browser version, country,
        screen size, device details, and pages visited. In addition, we also log
        the input story, link, and generated social media posts to help us
        improve the product. Any data we collect is stored securely, granting
        access to as few people as possible. Please write to us if you have any
        concerns with our data collection practices.
      </Typography>
      <Typography
        className="mt-3 mb-2"
        color="primary"
        variant="h5"
        gutterBottom
      >
        Terms of Use
      </Typography>
      <Typography paragraph align="justify" variant="body1" gutterBottom>
        See our Terms of Use (TOU) for JounaPilot
        {' '}
        <a href="https://journapilot.mcgovern.org/tnc">here</a>
        .
      </Typography>
      <Typography
        className="mt-4 mb-3"
        color="primary"
        variant="h3"
        gutterBottom
      >
        About Us
      </Typography>
      <Typography
        className="mt-3 mb-2"
        color="primary"
        variant="h5"
        gutterBottom
      >
        The Patrick J. McGovern Foundation
      </Typography>
      <Typography paragraph align="justify" variant="body1" gutterBottom>
        A global, 21st-century philanthropy, the Patrick J. McGovern Foundation
        is committed to bridging the frontiers of artificial intelligence, data
        science, and social impact. We are tech-inspired global changemakers,
        optimists, and visionaries advancing AI and data solutions to create a
        thriving, equitable, and sustainable future for all. Learn more
        {' '}
        <a href="https://www.mcgovern.org/about/">here</a>
        .
      </Typography>
      <Typography
        className="mt-3 mb-2"
        color="primary"
        variant="h5"
        gutterBottom
      >
        Data Solutions Team
      </Typography>
      <Typography paragraph align="justify" variant="body1" gutterBottom>
        The Data Solutions team at PJMF aims to identify, develop and deploy
        strategic data and AI products focused on unlocking opportunities and
        addressing broad challenges that the social good sector is facing now
        and will face in the near future. Learn more
        {' '}
        <a href="https://www.mcgovern.org/our-work/data-solutions/">here</a>
        .
      </Typography>
      <Typography
        className="mt-3 mb-2"
        color="primary"
        variant="h5"
        gutterBottom
      >
        Contact Us
      </Typography>
      <Typography paragraph align="justify" variant="body1" gutterBottom>
        <ListItem className="py-0" sx={{ display: 'list-item', margin: 0 }}>
          Have a question not covered here (how to delete an account)? Send us
          an email at
          <a href="mailto:datasolutions@mcgovern.org">
            datasolutions@mcgovern.org
          </a>
          .
        </ListItem>
        <ListItem className="py-0" sx={{ display: 'list-item', margin: 0 }}>
          Have product suggestions, feedback, or found a bug? Use the buttons at
          the bottom to send us your thoughts.
        </ListItem>
      </Typography>
      <Footer />
    </Box>
  );
}

export default About;
